import * as React from 'react'
import { Router } from '@reach/router'
import Layout from '@/container/Layout'
import SideBarProvider from '@/container/SideBarProvider'
import ErrorBoundary from '@/utils/ErrorBoundary'
const Home = React.lazy(() => import('@/templates/Home'))
const Overview = React.lazy(() => import('@/templates/Overview'))
const FAQs = React.lazy(() => import('@/templates/FAQs'))
const Product = React.lazy(() => import('@/templates/Product'))
const Setup = React.lazy(() => import('@/templates/Setup'))
const Profile = React.lazy(() => import('@/templates/Profile'))
const EmployeeData = React.lazy(() => import('@/templates/EmployeeData'))
const EmployeeEngagement = React.lazy(() => import('@/templates/EmployeeEngagement'))
const EmployeeTwoFA = React.lazy(() => import('@/templates/EmployeeTwoFA'))
const EmployeeInsightsDashboard = React.lazy(() => import('@/templates/EmployeeInsightsDashboard'))
const BenefitSelectionInsightDashboard = React.lazy(() => import('@/templates/BenefitSelectionInsightDashboard'))
const TotalBenefits = React.lazy(() => import('@/templates/TotalBenefits'))
const TotalBenefitsEnhanced = React.lazy(() => import('@/templates/TotalBenefitsEnhanced'))
const PageNotFound = React.lazy(() =>
	import('@/components/screens/PageNotFound')
)
const Dashboard = React.lazy(() => import('@/templates/Dashboard'))
const Reporting = React.lazy(() => import('@/templates/Reporting'))
const BenefitTakeup = React.lazy(() => import('@/templates/BenefitTakeup'))
const ApproveSelections = React.lazy(() => import('@/templates/ApproveSelections'))

import withAuth from '@/utils/withAuth'
import Spinner from '@/utils/Spinner'
import { isBrowser } from '@/utils'
import EmployerCCVManage from "@/templates/EmployerCCVManage";
import BenefitApprovals from "@/pages/Approvals/BenefitApprovals";

function App() {
	return (
		<ErrorBoundary>
			<SideBarProvider>
				<Layout>
					{isBrowser && (
						<React.Suspense
							fallback={
								<div className="fixed inset-0 flex items-center justify-center z-100">
									<Spinner />
								</div>
							}
						>
							<Router basepath="app">
								<PageNotFound default />
								<Home path="/home/" />
								<Overview path="/my-benefits/" />
								<Product path="/my-benefits/:id" />
								<Setup path="/my-benefits/:id/setup/*" />
								<Setup path="/my-benefits/:id/manage" />
								<FAQs path="/faqs/" />
								<Dashboard path="/dashboard/" />
								<EmployeeEngagement path="/dashboard/employee-engagement/" />
								<EmployeeTwoFA path="/dashboard/platform-settings/" />
								<EmployeeInsightsDashboard path="/dashboard/employee-insights/" />
								<BenefitSelectionInsightDashboard path="/dashboard/benefit-takeup/:benefitId" />
								<BenefitTakeup path="/dashboard/benefit-takeup" />
								<Profile path="/home/my-profile/" />
								<TotalBenefits path="/my-total-benefits/" />
								<TotalBenefitsEnhanced path="/my-total-benefits-enhanced/" />
								<EmployeeData path="/employee-data/" />
								<Reporting path="/reporting" />
								<EmployerCCVManage path="/ccv/manage" />
								<ApproveSelections path="/dashboard/approve-selections" />
								<BenefitApprovals path="/dashboard/approve-selections/:benefitId" />
							</Router>
						</React.Suspense>
					)}
				</Layout>
			</SideBarProvider>
		</ErrorBoundary>
	)
}

export default withAuth(App)
