import * as React from 'react'
import { node } from 'prop-types'
import SideBar from '@/components/widgets/SideBar'
import Switch from '@/utils/Switch'
import Loading from '@/components/screens/Loading'
import ViewApprovalsData from "../components/user/ViewApprovalsData";
const Statement = React.lazy(() => import('@/components/user/Statement'))
const Terms = React.lazy(() => import('@/components/user/Terms'))
const AddFamily = React.lazy(() => import('@/components/user/AddFamily'))
const MakeAClaim = React.lazy(() => import('@/components/user/MakeAClaim'))
const ChangePassword = React.lazy(() =>
	import('@/components/user/ChangePassword')
)
const AddLifeEvent = React.lazy(() => import('@/components/user/AddLifeEvent'))
const OptOut = React.lazy(() => import('@/components/user/OptOut'))
const UploadData = React.lazy(() => import('@/components/user/UploadData'))
const PayrollReport = React.lazy(() =>
	import('@/components/user/PayrollReport')
)

const ProviderReport = React.lazy(() =>
	import('@/components/user/ProviderReport')
)
const LifeEventReport = React.lazy(() =>
	import('@/components/user/LifeEventReport')
)
const PreSetupQuestions = React.lazy(() =>
	import('@/components/user/PreSetupQuestions')
)
const TwoFAQRSidebar = React.lazy(() => import('@/components/user/TwoFAQRSidebar'))
const TwoFADisableSidebar = React.lazy(() => import('@/components/user/TwoFADisableSidebar'))
const TwoFACodeSidebar = React.lazy(() => import('@/components/user/TwoFACodeSidebar'))

const ViewEmployeeData = React.lazy(() => import("@/components/user/ViewEmployeeData"))
const BenefitTakeupDetail = React.lazy(() => import('@/components/user/BenefitTakeupDetail'))

export const SideBarContext = React.createContext()

function reducer(state, { type, payload }) {
	switch (type) {
		case 'OPEN_SIDEBAR': {
			const { component, data } = payload

			return {
				...state,
				isOpen: true,
				component,
				data
			}
		}

		case 'CLOSE_SIDEBAR': {
			return { ...state, isOpen: false }
		}

		default: {
			throw new Error('action type missing')
		}
	}
}

function SideBarProvider({ children }) {
	const [{ component, isOpen, data }, dispatch] = React.useReducer(reducer, {
		isOpen: false,
		component: null
	})

	const openSideBar = ({ type, payload }) => {
		dispatch({
			type: 'OPEN_SIDEBAR',
			payload: {
				component: type,
				data: payload
			}
		})
	}

	const closeSideBarOnIcon = () => {
		dispatch({
			type: 'CLOSE_SIDEBAR'
		})
		if (data?.onClose) {
			data?.onClose(data?.isSuccess ? false : !data?.isSwitcherOn)
		}
		if (data?.isSuccess && data?.handleDisableSuccess) {
			data?.handleDisableSuccess()
		}
	}

	const closeSideBar = (param) => {
		dispatch({
			type: 'CLOSE_SIDEBAR'
		})
		if (data?.onClose) {
			data?.onClose(param ?? !data?.isSwitcherOn)
		}
		if (data?.isSuccess && data?.handleDisableSuccess) {
			data?.handleDisableSuccess()
		}
	}

	// https://thecore-test.co.uk/password-reset/emx881278CE97C1

	return (
		<SideBarContext.Provider
			value={{
				isOpen,
				openSideBar,
				closeSideBar
			}}
		>
			{children}
			{component && typeof window !== 'undefined' && (
				<SideBar
					isOpen={isOpen}
					openSideBar={openSideBar}
					closeSideBar={closeSideBarOnIcon}
				>
					<React.Suspense fallback={<Loading>Loading</Loading>}>
						<Switch value={component}>
							<Statement
								case="statement"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<AddFamily
								case="add-family-member"
								data={data}
								closeSideBar={closeSideBar}
								isOpen={isOpen}
							/>
							<Terms case="terms" data={data} closeSideBar={closeSideBar} />
							<Terms
								case="benefit-terms"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<MakeAClaim
								case="make-a-claim"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<ChangePassword
								case="change-password"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<AddLifeEvent
								case="add-life-event"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<OptOut case="opt-out" data={data} closeSideBar={closeSideBar} />
							<UploadData
								case="upload-data"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<PayrollReport
								case="payroll-report"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<ProviderReport
								case="provider-report"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<LifeEventReport
								case="lifeevent-report"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<PreSetupQuestions
								case="medical-questions"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<ViewEmployeeData
								case="view-employee-data"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<BenefitTakeupDetail
								case="view-benefit-takeup-detail"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<ViewApprovalsData
								case="view-approvals-data"
								data={data}
								closeSideBar={closeSideBar}
							/>
							<TwoFAQRSidebar case="two-fa-qr" data={data} closeSideBar={closeSideBar} />
							<TwoFADisableSidebar case="two-fa-disable" data={data} closeSideBar={closeSideBar} />
							<TwoFACodeSidebar case="two-fa-code" data={data} closeSideBar={closeSideBar} />
						</Switch>
					</React.Suspense>
				</SideBar>
			)}
		</SideBarContext.Provider>
	)
}

SideBarProvider.propTypes = {
	children: node.isRequired
}

export default React.memo(SideBarProvider)
